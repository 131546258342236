import React, { useState, useEffect, useRef } from 'react';
import Layout from '@src/components/Layout';
import Calculate from '@src/util/calculate';
import region from '@src/util/region'
import MapElection from '@src/components/MapElection'
import ScorebarChart from '@src/components/ScorebarChart'

import { Select, Modal, Collapse } from 'antd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';

// import MapElectionBk from '../components/MapElectionOrigin'
import './index.css';
import textZone from "../util/election_text.json"

import imageSchoolS from '/images/krt-school-s.png';
import imageSchoolL from '/images/krt-school-l.png';
import imageSchoolX from '/images/krt-school-x.png';
import imageSchoolXL from '/images/krt-school-xl.png';

import obec_icon from '/images/home12.png'
import dla_icon from '/images/home10.png'
import bpp_icon from '/images/home8.png'
import opec_icon from '/images/home13.png'
import onab_icon from '/images/home11.png'
import sp_icon from '/images/home14.png'
import api from '../util/api';

const { Option } = Select;
const { Panel } = Collapse;

const TabPanel = (props) => {
    const { children, tab, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={tab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {tab === index && (
                <div className='py-6'>
                    {children}
                </div>
            )}
        </div>
    );
}

export default function Election(props) {


    // const dataZone = props.pageContext.dataForZome
    // const dataTumbon = props.pageContext.data


    const [dataZone, setDataZone] = useState([])
    const [dataTumbon, setDataTumbon] = useState([])
    const [data, setData] = useState([])
    const [data_map, setDataMap] = useState([])
    const [tab, setTab] = useState(0);
    const [filter, setFilter] = useState({
        view: 'country',
        year: "2566",
        region: "ทั้งหมด",
        province_name: "ทั้งหมด",
        zone: null,
    });
    const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#a3a3a3',])
    const [expanded, setExpanded] = useState(null);
    const [zoneText, setZoneText] = useState({
        city: null,
        text: null
    });
    const [ismodalref, setModalRef] = useState(false)

    useEffect(async () => {
        let res = await api.getInitData('area-election')
        console.log('res data',res.data.reduce((acc, cur) => acc + (cur.total), 0))
        console.log('res dataForZome',res.dataForZome.reduce((acc, cur) => acc + (cur.total), 0))
        setDataZone(res.dataForZome)
        setDataTumbon(res.data)
    }, [])

    useEffect(() => {
        if (dataZone.length === 0 || dataTumbon.length === 0) return

        let data_select = dataTumbon
        if (filter.region !== 'ทั้งหมด') {
            data_select = data_select.filter(item => region.getRegion(item.province_name) === filter.region)
        }
        if (filter.province_name !== 'ทั้งหมด') {
            data_select = data_select.filter(item => item.province_name === filter.province_name)
        }
        if (filter.zone) {
            data_select = data_select.filter(item => item.province_name === filter.province_name && item.zone === filter.zone)
        }
        let response = onProcessData(convertMapContent(dataZone.filter(item => item.zone)))
        setData(data_select)
        setDataMap(response)
        if (filter.province_name !== 'ทั้งหมด' && filter.zone) {
            let city = dataZone.filter(item => item.province_name === filter.province_name && item.zone === parseInt(filter.zone))
            city = (city.length > 0 ? city[0].city_name.length > 0 ? [...new Set(city[0].city_name)] : null : null)
            let text = textZone.filter(item => item.province === filter.province_name && item.zone === parseInt(filter.zone))[0].text
            setZoneText({ city: city, text: text })
        } else {
            setZoneText({ city: null, text: null })
        }
    }, [filter, dataZone, dataTumbon])

    const convertMapContent = (data) => {
        let res = {}
        data.forEach((item) => {
            if (item.zone) {
                let index = `${item.province_name}_${item.zone}`
                if (!res[index]) {
                    res[index] = {
                        province_name: item.province_name,
                        zone: item.zone,
                        total: item.total ? item.total : 0,
                        sp: item.sp ? item.sp : 0,
                        abe: item.abe,
                        province20: item.province20 ? item.province20 : 0,
                        total_fund: (
                            (item.cct ? item.cct : 0) +
                            (item.vec ? item.vec : 0) +
                            (item.krt ? item.krt : 0) +
                            (item.tps ? item.tps : 0) +
                            (item.target_group ? item.target_group : 0) +
                            (item.abe ? item.abe : 0) +
                            (item.province20 ? item.province20 : 0) +
                            (item.tsqp ? item.tsqp : 0)
                        ),
                        cct: item.cct,
                        vec: item.vec,
                        krt: item.krt,
                        tps: item.tps,
                        target_group: item.target_group,
                        tsqp: item.tsqp,
                        city_list: [item.city_name],
                        tumbon_list: [item.tumbon_name]
                    }
                } else {
                    res[index]['total'] += 0
                    res[index]['sp'] += 0
                    res[index]['abe'] += item.abe
                    res[index]['province20'] += item.province20
                    res[index]['cct'] += item.cct
                    res[index]['vec'] += item.vec
                    res[index]['krt'] += item.krt
                    res[index]['tps'] += item.tps
                    res[index]['target_group'] += item.target_group
                    res[index]['tsqp'] += item.tsqp
                    res[index]['total_fund'] += (
                        (item.cct ? item.cct : 0) +
                        (item.vec ? item.vec : 0) +
                        (item.krt ? item.krt : 0) +
                        (item.tps ? item.tps : 0) +
                        (item.target_group ? item.target_group : 0) +
                        (item.abe ? item.abe : 0) +
                        (item.province20 ? item.province20 : 0) +
                        (item.tsqp ? item.tsqp : 0)
                    )
                    res[index]['city_list'].push(item.city_name)
                    res[index]['tumbon_list'].push(item.tumbon_name)
                }
            }
        })
        return Object.values(res)
    }

    const onProcessData = (data) => {
        const _genColorRange = (color_set, value) => {
            color_set = color_set.sort((a, b) => b.value - a.value)
            let color = ''
            for (let i in color_set) {
                if (value > color_set[i].value) {
                    color = color_set[i].color
                    break;
                }
            }
            return color
        }

        let result = data

        result.map(element => {
            element.city_list = [...new Set(element.city_list)]
            element.tumbon_list = [...new Set(element.tumbon_list)]
            if (!isFinite(element['total_fund'] / element['total_fund'])) {
                element['percent'] = 0
            } else {
                element['percent'] = ((element['total_fund'] / result.map(item => item.total_fund).reduce((a, b) => a + b, 0)) * 100)
            }
            return element
        })

        let data_color = [
            { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.total_fund)), count: 0, text: `มาก` },
            { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.total_fund)), count: 0, text: `ปานกลาง` },
            { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.total_fund)), count: 0, text: `ปานกลาง` },
            { color: border_color[3], value: 0, count: 0, text: `น้อย` },
            { color: border_color[4], value: -100, count: 0, text: `น้อย` },
        ]
        result.forEach(element => {
            element['color'] = _genColorRange(data_color, element.total_fund)
            if (filter.region !== 'ทั้งหมด') {
                element['color'] = filter.region === region.getRegion(element.province_name) ? _genColorRange(data_color, element.total_fund) : border_color[4]
            }
            if (filter.province_name !== 'ทั้งหมด') {
                element['color'] = filter.province_name === element.province_name ? _genColorRange(data_color, element.total_fund) : border_color[4]
            }
            if (filter.zone) {
                element['color'] = filter.province_name === element.province_name && filter.zone === element.zone ? _genColorRange(data_color, element.total_fund) : border_color[4]
            }
            // element['opacity'] = 0.5
            element['percent'] = parseFloat(element['percent'].toFixed(2))
        })
        let res = result
        return res
    }

    const Card = ({ title, value, unit }) => {
        return <div className='w-full h-[170px] text-center rounded-md shadow-md bg-white px-4 py-4'>
            <div className='h-full my-4'>
                <h3 className='text-lg xl:text-xl m-0'>{title}</h3>
                <h1 className='font-bold text-2xl xl:text-3xl m-0'>{value}</h1>
                <h3 className='font-bold text-lg xl:text-xl m-0'>{unit}</h3>
            </div>
        </div>
    }

    const ExpandCard = ({ summary, detail }) => {
        return (
            <div className='my-4'>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        className=' border-b border-neutral-400'
                        sx={{ borderBottom: "1px dotted gray" }}
                        expandIcon={detail ? <ExpandMoreIcon /> : <></>}
                        aria-controls={`${summary.title}-content`}
                        id={`${summary.title}-header`}
                    >
                        <div className='flex justify-between w-full'>
                            <h1 className='text-lg md:text-2xl font-bold m-0'>
                                {summary?.title}
                            </h1>
                            <h1 className='text-xl md:text-3xl font-bold m-0 pr-4'>
                                {summary?.value?.toLocaleString("en-US")}
                                <span className='pl-4'>{summary?.unit}</span>
                            </h1>
                        </div>
                    </AccordionSummary>
                    {detail && <AccordionDetails className='bg-neutral-100'>
                        {
                            detail.map((ele, idx) => {
                                return (
                                    <div key={ele.title + '_' + idx} className='flex justify-between my-2 mx-4'>
                                        <span className='text-left text-base md:text-lg font-bold'>{ele.title}</span>
                                        <span className='text-right'>
                                            <span className='text-lg md:text-2xl font-bold'>{ele.value?.toLocaleString("en-US")}</span>
                                            <span className='pl-2 text-xl font-bold'>{ele.unit}</span>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </AccordionDetails>}
                </Accordion >
            </div>
        )
    }

    const handleChangeExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClickMap = ({ province_name, zone }) => {
        setFilter({ ...filter, region: 'ทั้งหมด', province_name: province_name, zone: parseInt(zone) })
    }

    const FilterSection = () => {
        return <>
            <h1 className='text-5xl font-bold text-[#038967] text-center'>การดำเนินงาน กสศ. ภาพรวมระดับประเทศ</h1>
            <h1 className='text-3xl font-semibold text-[#038967] text-center'>ภาพรวมระดับประเทศ</h1>
            <div className='text-center mx-auto'>
                <Tabs
                    value={tab}
                    onChange={(event, newValue) => setTab(newValue)}
                    aria-label="icon position tabs example"
                    centered
                >
                    <Tab label="ภาพรวมพื้นที่เขตเลือกตั้ง" />
                    <Tab label="การดำเนินงานของกสศ" />
                </Tabs>
            </div>
            <div className='flex flex-row mx-auto text-center my-6 '>
                <div className='flex flex-wrap mx-auto'>
                    <div className='flex mx-auto'>
                        <h5 className="text-sm m-0 ml-[9px] my-auto" >ภาค: </h5>
                        <Select
                            className='new-design-select'
                            showSearch
                            placeholder="ภาค"
                            defaultValue="ทั้งหมด"
                            value={filter.region}
                            style={{ width: 180, margin: 4 }}
                            onChange={(val) => {
                                setFilter({ ...filter, region: val, province_name: 'ทั้งหมด', zone: null })
                            }}
                        >
                            <Option value={'ทั้งหมด'}>ทั้งหมด</Option>
                            <Option value={'ภาคตะวันออกเฉียงเหนือ'}>ภาคตะวันออกเฉียงเหนือ</Option>
                            <Option value={'ภาคเหนือ'}>ภาคเหนือ</Option>
                            <Option value={'ภาคกลาง'}>ภาคกลาง</Option>
                            <Option value={'ภาคใต้'}>ภาคใต้</Option>
                        </Select>
                    </div>
                    <div className='flex mx-auto'>
                        <h5 className="text-sm m-0 ml-[9px] my-auto" >จังหวัด: </h5>
                        <Select
                            className='new-design-select'
                            showSearch
                            placeholder="จังหวัด"
                            defaultValue="ทั้งหมด"
                            value={filter.province_name}
                            style={{ width: 180, margin: 4 }}
                            onChange={(val) => {
                                setFilter({ ...filter, province_name: val, zone: null })
                            }}
                        >
                            <Option value={'ทั้งหมด'}>ทั้งหมด</Option>
                            {
                                [...new Set(
                                    filter.region === "ทั้งหมด" ?
                                        dataTumbon.map(item => item.province_name) :
                                        dataTumbon.filter(item => region.getRegion(item.province_name) === filter.region).map(item => item.province_name))
                                ]
                                    .filter(x => x !== 'ไม่ระบุ')
                                    .sort()
                                    .map(x => <Option value={x}>{x}</Option>)
                            }
                        </Select>
                    </div>
                    <div className='flex mx-auto'>
                        <h5 className="text-sm m-0 ml-[9px] my-auto" >เขต: </h5>
                        <Select
                            className='new-design-select'
                            showSearch
                            disabled={filter.province_name === 'ทั้งหมด'}
                            placeholder="เขต"
                            defaultValue="ทั้งหมด"
                            value={filter.zone}
                            style={{ width: 180, margin: 4 }}
                            onChange={(val) => {
                                if (val === 'ทั้งจังหวัด') {
                                    setFilter({ ...filter, zone: null })
                                } else {
                                    setFilter({ ...filter, zone: val })
                                }
                            }}
                        >
                            <Option value={'ทั้งจังหวัด'}>ทั้งจังหวัด</Option>
                            {
                                [...new Set(textZone.filter(x => x.province === filter.province_name))]
                                    .map(x => x.zone)
                                    .sort((a, b) => a - b)
                                    .map(x => <Option value={x}>{x}</Option>)
                            }

                        </Select>
                    </div>
                </div>
            </div>
        </>
    }

    return (
        <Layout>
            <div className="w-full rounded-xl m-auto px-2 2xl:px-32 2xl:py-16 py-24 bordered">
                <div className='grid grid-cols-2'>
                    <div className='relative col-span-2 xl:col-span-1 mx-auto'>
                        <div className='block xl:hidden'>
                            <FilterSection />
                        </div>
                        <MapElection dataSvg={data_map} data={dataZone} onClick={handleClickMap} />
                        <div className='relative 2xl:absolute 2xl:top-16 2xl:right-8 z-1 flex'>
                            <div className='h-full mx-auto mt-6 xl:m-0 rounded-lg flex flex-col shadow-md bg-neutral-100 px-2 py-3'>
                                <span className='font-bold center mx-1 mb-2'>สัดส่วนกลุ่มเป้าหมายที่ได้รับทุนตามเขตเลือกตั้ง</span>
                                <div className='flex'>
                                    <div className='flex flex-col'>
                                        <div class={`h-3 w-16 bg-[#1b5e20]`}>
                                        </div>
                                        <div class="font-bold text-left">
                                            มาก
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div class={`h-3 w-16 bg-[#388e3c]`}>
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div class={`h-3 w-16 bg-[#66bb6a]`}>
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div class={`h-3 w-16 bg-[#a5d6a7]`}>
                                        </div>
                                        <div class="font-bold text-right">
                                            น้อย
                                        </div>
                                    </div>
                                    <div className='ml-4  flex flex-col'>
                                        <div class={`h-3 w-16 bg-[#94a3b8]`}>
                                        </div>
                                        <div class="font-bold text-center">
                                            ไม่มีข้อมูล
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' col-span-2 xl:col-span-1 my-12'>
                        <div className='hidden xl:block'>
                            <FilterSection />
                        </div>
                        <div className='text-center'>
                            <h1 className='text-2xl lg:text-3xl font-bold text-black mb-0'>
                                {
                                    filter.region !== 'ทั้งหมด' && !filter.zone ?
                                        `จำนวน ${[...new Set(data.filter(item => item.zone).map(item => `${item.province_name}_${item.zone}`))].length} เขต` :
                                        (filter.province_name !== 'ทั้งหมด' ?
                                            <>{`${filter.province_name} ${filter.zone ?
                                                `เขต ${filter.zone}` :
                                                `จำนวน ${[...new Set(data.filter(item => item.zone).map(item => `${item.province_name}_${item.zone}`))].length} เขต`}`}</> :
                                            <>{`ในพื้นที่ 400 เขต`}</>)
                                }
                            </h1>
                            {
                                zoneText.city &&
                                <div className='flex my-2'>
                                    <div className='mx-auto'>
                                        {
                                            zoneText.city.map(ele => {
                                                return <Chip className='mx-1' variant="outlined" label={ele} />
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                zoneText.text &&
                                <div className='text-center px-4 my-2'>
                                    {zoneText.text}
                                </div>
                            }
                            <h3 className='text-lg font-bold text-neutral-500'>
                                จำนวนเด็กและเยาวชนวัยเรียน
                            </h3>
                            <h1 className='text-5xl lg:text-7xl font-bold text-[##038967] mt-6 mb-0'>
                                <span>{data.reduce((acc, cur) => acc + (cur.total + cur.total_oosc), 0).toLocaleString('en-Us')}</span>
                                <span className='pl-4'>คน</span>
                            </h1>
                            {/* <div className='w-full mx-auto'>
                <div id="sideCard" className='mt-8'>
                  <ScorebarChart
                    valueSummary={data.reduce((acc, cur) => acc + (cur.total + cur.total_oosc), 0).toLocaleString('en-Us')}
                    dataSet={[
                      {
                        label: 'นักเรียนในระบบการศึกษา',
                        value: data.reduce((acc, cur) => acc + cur.total, 0)
                      },
                      {
                        label: `เด็กและเยาวชน\n ที่ไม่มีข้อมูลใน\nระบบการศึกษา`,
                        value: data.reduce((acc, cur) => acc + cur.total_oosc, 0)
                      },
                    ]}
                    height={40}
                    barHeight={40}
                    top={"top-[25px]"}
                  />
                </div>
                <div className='flex justify-between px-2 mb-4'>
                  <h2 className='text-[#3ec06a] text-left'>นักเรียนในระบบการศึกษา </h2>
                  <h2 className='text-[#4b7fce] text-right'>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</h2>
                </div>
              </div> */}
                        </div>
                        <TabPanel tab={tab} index={0}>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className='col-span-3 md:col-span-1'>
                                    <Card
                                        // title={<span>เด็กนักเรียนในระบบการศึกษา</span>}
                                        title={<span>เด็กในระบบการศึกษาขั้นพื้นฐาน</span>}
                                        value={<span className='text-[#048967]'>{data.reduce((acc, cur) => acc + (cur.total), 0).toLocaleString('en-Us')}</span>}
                                        unit={'คน'} />
                                </div>
                                <div className='col-span-3 md:col-span-1'>
                                    <Card
                                        title={<span>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (3 - 17 ปี)</span>}
                                        value={<span className='text-[#1611FF]'>{data.reduce((acc, cur) => acc + cur.total_oosc, 0).toLocaleString('en-Us')}</span>}
                                        unit={'คน'} />
                                </div>
                                <div className='col-span-3 md:col-span-1'>
                                    <Card
                                        title={<span>จำนวนโรงเรียนทั้งหมด</span>}
                                        value={<span className='text-[#1611FF]'>{
                                            data.reduce((acc, cur) => acc + cur.sum_sungkud_cct +
                                                cur.sum_sungkud_pbb + cur.sum_sungkud_dla + cur.sum_sungkud_opec +
                                                cur.sum_sungkud_onab + cur.sum_sungkud_other, 0).toLocaleString('en-Us')
                                        }</span>}
                                        unit={'โรงเรียน'} />
                                </div>
                            </div>
                            <ExpandCard
                                summary={{ title: 'เด็กในระบบการศึกษาขั้นพื้นฐาน', value: data.reduce((acc, cur) => acc + (cur.total), 0).toLocaleString('en-Us'), unit: 'คน' }}
                                // summary={{ title: 'นักเรียนในระบบการศึกษา', value: data.reduce((acc, cur) => acc + cur.total, 0).toLocaleString('en-Us'), unit: 'คน' }}
                                detail={[
                                    { title: 'อนุบาล', value: data.reduce((acc, cur) => acc + cur.class_k, 0).toLocaleString('en-Us'), unit: 'คน' },
                                    { title: 'ประถมศึกษา', value: data.reduce((acc, cur) => acc + cur.class_p, 0).toLocaleString('en-Us'), unit: 'คน' },
                                    { title: 'มัธยมศึกษาตอนต้น', value: data.reduce((acc, cur) => acc + cur.class_m, 0).toLocaleString('en-Us'), unit: 'คน' },
                                    { title: 'มัธยมศึกษาตอนปลาย', value: data.reduce((acc, cur) => acc + cur.class_h, 0).toLocaleString('en-Us'), unit: 'คน' },
                                ]} />
                            <div className='my-4'>
                                <p className='text-2xl font-bold mb-2'>
                                    โรงเรียนแบ่งตามสังกัด
                                </p>
                                <div className='grid grid-cols-3 md:col-span-6 gap-2'>
                                    {
                                        [
                                            { icon: obec_icon, title: 'สพฐ.', value: data.reduce((acc, cur) => acc + cur.sum_sungkud_cct, 0).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                                            { icon: dla_icon, title: 'อปท.', value: data.reduce((acc, cur) => acc + cur.sum_sungkud_dla, 0).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                                            { icon: bpp_icon, title: 'ตชด.', value: data.reduce((acc, cur) => acc + cur.sum_sungkud_pbb, 0).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                                            { icon: opec_icon, title: 'สช.', value: data.reduce((acc, cur) => acc + cur.sum_sungkud_opec, 0).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                                            { icon: onab_icon, title: 'พศ.', value: data.reduce((acc, cur) => acc + cur.sum_sungkud_onab, 0).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                                            { icon: sp_icon, title: 'อื่นๆ', value: data.reduce((acc, cur) => acc + cur.sum_sungkud_other, 0).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                                        ].map((ele, idx) => {
                                            return <div key={`${ele.title}-card-${idx}`} className='col-span-1 md:col-span-1 xl:col-span-1'>
                                                <div className='w-full py-4 border rounded-md shadow-lg text-center bg-white'>
                                                    <img className='mx-auto' src={ele.icon} width={60} />
                                                    <p className='my-1 text-xl xl:text-2xl'>{ele.title}</p>
                                                    <p className='my-1 text-2xl xl:text-3xl font-bold'>{ele.value}</p>
                                                    <p className='my-1 text-lg xl:text-xl'>{ele.unit}</p>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='my-4'>
                                <p className='text-2xl font-bold mb-2'>
                                    โรงเรียนแบ่งตามขนาดโรงเรียน
                                </p>
                                <div className='grid grid-cols-12 gap-2'>
                                    {
                                        [
                                            { icon: imageSchoolS, title: 'รร.ขนาดเล็ก', subtitle: 'จำนวนโรงเรียน', value: data.reduce((acc, cur) => acc + cur.sum_school_sizeS, 0).toLocaleString('en-Us'), unit: 'รร.' },
                                            { icon: imageSchoolL, title: 'รร.ขนาดกลาง', subtitle: 'จำนวนโรงเรียน', value: data.reduce((acc, cur) => acc + cur.sum_school_sizeL, 0).toLocaleString('en-Us'), unit: 'รร.' },
                                            { icon: imageSchoolX, title: 'รร.ขนาดใหญ่', subtitle: 'จำนวนโรงเรียน', value: data.reduce((acc, cur) => acc + cur.sum_school_sizeX, 0).toLocaleString('en-Us'), unit: 'รร.' },
                                            { icon: imageSchoolXL, title: 'รร.ขนาดใหญ่พิเศษ', subtitle: 'จำนวนโรงเรียน', value: data.reduce((acc, cur) => acc + cur.sum_school_sizeXL, 0).toLocaleString('en-Us'), unit: 'รร.' },
                                        ].map((ele, idx) => {
                                            return <div key={`${ele.title}-card-${idx}`} className='col-span-12 md:col-span-6 flex-col'>
                                                <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                                                    <img className="flex self-center xl:mx-4 w-[72px]" src={ele.icon} alt="images" />
                                                    <div className='flex flex-1 flex-col'>
                                                        <span className='text-xl font-bold text-center mb-0'>{ele.title}</span>
                                                        <span className='text-sm font-bold text-center'>{ele.subtitle}</span>
                                                        <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{`${ele.value} ${ele.unit}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <p className="font-[22px] mb-6 mt-6 text-gray-800 underline-offset-auto ">
                                {`*หมายเหตุ มีจำนวนโรงเรียนที่ไม่มีข้อมูลนักเรียน ${(data.reduce((acc, cur) => acc + cur.sum_sungkud_cct +
                                    cur.sum_sungkud_pbb + cur.sum_sungkud_dla + cur.sum_sungkud_opec +
                                    cur.sum_sungkud_onab + cur.sum_sungkud_other, 0) -
                                    data.reduce((acc, cur) => acc +
                                        (cur.sum_school_sizeS + cur.sum_school_sizeL + cur.sum_school_sizeX + cur.sum_school_sizeXL), 0)).toLocaleString('en-Us')} โรงเรียน จึงไม่สามารถนำมาประมวลผลขนาดโรงเรียนได้`}
                            </p>
                        </TabPanel>
                        <TabPanel tab={tab} index={1}>
                            <div className='w-full mx-auto'>
                                <div id="sideCard" className='mt-8'>
                                    <ScorebarChart
                                        valueSummary={data.reduce((acc, cur) => acc + (cur.total + cur.total_oosc), 0).toLocaleString('en-Us')}
                                        dataSet={[
                                            {
                                                label: 'นักเรียนในระบบการศึกษา',
                                                value: data.reduce((acc, cur) => acc + cur.total, 0)
                                            },
                                            {
                                                label: `เด็กและเยาวชน\n ที่ไม่มีข้อมูลใน\nระบบการศึกษา`,
                                                value: data.reduce((acc, cur) => acc + cur.total_oosc, 0)
                                            },
                                        ]}
                                        height={40}
                                        barHeight={40}
                                        top={"top-[25px]"}
                                    />
                                </div>
                                <div className='flex justify-between px-2 mb-4'>
                                    <h2 className='text-[#3ec06a] text-left'>นักเรียนในระบบการศึกษา </h2>
                                    <h2 className='text-[#4b7fce] text-right'>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</h2>
                                </div>
                            </div>
                            <div className='shadow-2xl w-full bg-transparent rounded-md mx-auto'>
                                <h1 className='text-xl font-bold'>จำนวนเด็กและเยาวชนจำแนกตามทุน</h1>
                                <ExpandCard
                                    summary={{ title: 'ทุนสร้างโอกาส', value: data.reduce((acc, cur) => acc + (cur.cct + cur.vec + cur.krt + cur.tps + cur.target_group), 0), unit: 'คน' }}
                                    detail={[
                                        { title: 'ทุนเสมอภาค', value: data.reduce((acc, cur) => acc + cur.cct, 0).toLocaleString("en-US") },
                                        { title: 'ทุนนวัตกรรมสายอาชีพชั้นสูง', value: data.reduce((acc, cur) => acc + cur.vec, 0).toLocaleString("en-US") },
                                        { title: 'ทุนครูรัก(ษ์)ถิ่น', value: data.reduce((acc, cur) => acc + cur.krt, 0).toLocaleString("en-US") },
                                        { title: 'ทุนพระกนิษฐาสัมมาชีพ', value: data.reduce((acc, cur) => acc + cur.tps, 0).toLocaleString("en-US") },
                                        { title: 'ทุนพัฒนาอาชีพ', value: data.reduce((acc, cur) => acc + cur.target_group, 0).toLocaleString("en-US") },
                                    ]} />
                                <ExpandCard
                                    summary={{ title: 'นวัตกรรมต้นแบบ', value: data.reduce((acc, cur) => acc + (cur.province20 + cur.abe + cur.krt), 0), unit: 'คน' }}
                                    detail={[
                                        { title: 'เด็กที่ไม่มีข้อมูลในระบบการศึกษา (ABE)', value: data.reduce((acc, cur) => acc + cur.province20, 0).toLocaleString("en-US") },
                                        { title: 'เด็กปฐมวัย (ABE)', value: data.reduce((acc, cur) => acc + cur.abe, 0).toLocaleString("en-US") },
                                        { title: 'โครงการโรงเรียนพัฒนาตนเอง (TSQP)', value: data.reduce((acc, cur) => acc + cur.tsqp, 0).toLocaleString("en-US") },
                                    ]} />
                            </div>
                        </TabPanel>
                        <a onClick={() => { setModalRef(true) }} className="font-[22px] mb-6 mt-6 text-gray-800 underline-offset-auto ">
                            <InfoIcon /> แหล่งที่มาของข้อมูล
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                width={800}
                visible={ismodalref}
                // title="แหล่งที่มาของข้อมูล"
                onOk={() => setModalRef(false)}
                onCancel={() => setModalRef(false)}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <div>
                    <div>
                        <span className='text-lg font-bold'>แหล่งที่มาข้อมูล</span><br />
                        <span className='pl-6'>ข้อมูลการช่วยเหลือกลุ่มเป้าหมายในแต่ละโครงการของ กสศ. ประกอบด้วย</span><br />
                        <br />
                        <span className='text-lg font-bold'>ข้อมูลนักเรียนในระบบการศึกษา</span><br />
                        <span className='pl-6'>ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566 </span><br />
                        <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                            <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                                <ul className='bg-[#f0fdf4] rounded-md py-4'>
                                    <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                                    <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                                    <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                                    <li className='pl-6'>- กองทัพบก</li>
                                    <li className='pl-6'>- กองทัพเรือ</li>
                                    <li className='pl-6'>- กองทัพอากาศ</li>
                                    <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                                    <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                                    <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                                    <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                                    <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                                    <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                                    <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                                    <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                                    <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                                    <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                                    <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                                    <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                                </ul>
                            </Panel>
                        </Collapse>
                        <br />
                        <span className='text-lg font-bold'>ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 17 ปี)</span><br />
                        <span className='pl-6'>(1) ข้อมูลทะเบียนราษฎร์ ปี 2566 </span><br />
                        <span className='pl-6'>(2) ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566</span><br />
                        <span className='pl-6'>(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปี 2564</span><br />
                        <br />
                        <span className='text-lg font-bold'>ข้อมูลจำนวนสถานศึกษา</span><br />
                        <span className='pl-6'>ข้อมูลจำนวนสถานศึกษาในแต่ละสังกัดปีการศึกษา 2566 </span><br />
                        <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                            <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                                <ul className='bg-[#f0fdf4] rounded-md py-4'>
                                    <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                                    <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                                    <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                                    <li className='pl-6'>- กองทัพบก</li>
                                    <li className='pl-6'>- กองทัพเรือ</li>
                                    <li className='pl-6'>- กองทัพอากาศ</li>
                                    <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                                    <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                                    <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                                    <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                                    <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                                    <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                                    <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                                    <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                                    <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                                    <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                                    <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                                    <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                                </ul>
                            </Panel>
                        </Collapse>
                        <br />
                        <span className='text-lg font-bold'>ทุนสร้างโอกาส</span><br />
                        <span className='pl-6'><b>ทุนเสมอภาค</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ปีการศึกษา 2566 </span><br />
                        <span className='pl-6'><b>ทุนครูรัก(ษ์)ถิ่น</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
                        <span className='pl-6'><b>ทุนนวัตกรรมสายอาชีพ</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
                        <span className='pl-6'><b>ทุนพระกนิษฐา</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
                        <span className='pl-6'><b>ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน</b> ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2564</span>
                        <br />
                        <span className='text-lg font-bold'>นวัตกรรมต้นแบบ</span><br />
                        <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา </b> ข้อมูลการสำรวจแผนงาน 20 จังหวัดเสมอภาค ปี 2563</span><br />
                        <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</b> ข้อมูลการสำรวจแผนงานครูและเด็กนอกระบบ ปี 2563 </span><br />
                        <span className='pl-6'><b>เด็กปฐมวัยยากจนใน ศพด.</b> ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาค 	ในปีการศึกษา 2563 </span><br />
                        <span className='pl-6'><b>โครงการ TSQP </b> ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2564 </span><br />
                        <br />
                        <span className='text-lg font-bold text-[#15803d]'>*ประมวลผล ณ เดือนสิงหาคม 2567</span><br />
                    </div>
                </div>
            </Modal>
        </Layout >
    );
}