import React, { useState, useEffect } from "react";
import * as d3 from "d3";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const ScorebarChart = (props) => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  let sampleData = props.dataSet;

  function groupData(data, total) {
    // use scale to get percent values
    const percent = d3.scaleLinear().domain([0, total]).range([0, 100]);
    // filter out data that has zero values
    // also get mapping for next placement
    // (save having to format data for d3 stack)
    let cumulative = 0;
    const _data = data
      .map((d) => {
        cumulative += d.value;
        return {
          value: d.value,
          // want the cumulative to prior value (start of rect)
          cumulative: cumulative - d.value,
          label: d.label,
          percent: percent(d.value),
        };
      })
      .filter((d) => d.value > 0);
    return _data;
  }

  function wrap(text, width, xValue) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y") - 20,
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(word).append("tspan").attr("x", xValue).attr("y", y);

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));

        y = (parseFloat(y) + 15).toString();
        // console.log(tspan.node().getComputedTextLength());

        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", xValue)
            .attr("y", y)
            .text(word);
        }
      }
    });
  }

  useEffect(() => {
    document.getElementById("bars").innerHTML = "";

    var graph_div = document.getElementById("sideCard");

    const total = d3.sum(sampleData, (d) => d.value);
    const _data = groupData(sampleData, total);

    let config = {
      f: d3.format(".1f"),
      margin: { top: 20, right: 10, bottom: 20, left: 10 },
      width: graph_div.clientWidth,
      height: props.height ? props.height : 100,
      barHeight: props.barHeight ? props.barHeight : 30,
      colors: (props.colorSet
        ? props.colorSet
        : ["#3ec06a", "#4b7fce", "#8aa0da", "#dfc050"]),
    };
    const { f, margin, width, height, barHeight, colors } = config;
    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;
    const halfBarHeight = barHeight / 2;


    var mouseover = function (e, d) {

      tooltip
        .html(`<div>${d.label}</div>`)
        .style("opacity", 1)
    }
    var mousemove = function (e, d) {

      tooltip
        .style("top", (e.layerY + 40) + "px")
        .style("left", (e.layerX - 280) + "px")
    }
    var mouseleave = function (e, d) {
      tooltip
        .style("opacity", 0)
    }

    const xScale = d3.scaleLinear().domain([0, total]).range([0, w]);

    const selection = d3
      .select("#bars")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")



    // stack rect for each data value
    selection
      .selectAll("rect")
      .data(_data)
      .enter()
      .append("rect")
      .attr("class", "rect-stacked")
      .attr("x", (d) => xScale(d.cumulative))
      .attr("y", h / 2 - halfBarHeight)
      .attr("height", barHeight)
      .attr("width", (d) => xScale(d.value))
      .style("fill", (d, i) => colors[i]);

    // add values on bar
    selection
      .selectAll(".text-value")
      .data(_data)
      .enter()
      .append("text")
      .attr("class", "text-value")
      .attr("text-anchor", "middle")
      .attr("x", (d) => xScale(d.cumulative) + xScale(d.value) / 2)
      .attr("y", h / 2 + 5)
      .text((d) => numberWithCommas(d.value));

    // selection
    // .selectAll(".text-label")
    // .data(_data)
    // .enter()
    // .append("text")
    // .attr("class", "text-label")
    // .attr("text-anchor", "middle")
    // .attr("font-size", "12px")
    // .attr("x", (d) => xScale(d.cumulative) + xScale(d.value) / 2)
    // .attr("y", h / 2 + halfBarHeight * 1.1 + 20)
    // .style("fill", (d, i) => colors[i])
    // .text((d) => d.label)
    // .call(wrap, 40, (d) => xScale(d.cumulative) + xScale(d.value) / 2)
    // .on("mouseover", mouseover)
    // .on("mousemove", mousemove)
    // .on("mouseleave", mouseleave)


    var tooltip = d3.select("#bars")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("position", "absolute")
      .style("z-index", "3")
  }, [sampleData]);

  return (
    <>
      <div className="relative">
        <div id="bars"></div>
      </div>
    </>
  );
};

export default ScorebarChart;
